$size: 36px;
.mpk-picture-profile{
  background: #d6d0c9;
  border-radius: 50%;
  width: $size;
  height: $size;
  position: relative;
  border: 2px solid white;
  .md-icon{
    font-size: 14px
  }
  img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    position: relative;
    z-index: 1
  }
  .default-img{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 0;
  }
}