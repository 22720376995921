@import '../../styles/var';

.mpk-article{
  background: white;
  > div {
    width: $mpk-article-width;
    margin: 0 auto;
    position: relative;
  }
  .ills{
    padding-top:$mpk-padding-N;
    > * {
      width:100%;
    }
  }
  .content{
    padding: $mpk-padding-N 0;
    .title{
      font-size: $mpk-font-size-XXXL;
      line-height: $mpk-font-size-XXXL + 6;
      font-weight: $mpk-font-weight-B;
    }
    .info{
      margin-top:$mpk-margin-S;
    }
    .deck{
      font-size: $mpk-font-size-XXL;
      font-weight: $mpk-font-weight-L;
      color:$mpk-font-color-D3;
      margin-top:$mpk-margin-N;
    }
    .body{
      margin-top:$mpk-margin-N;
      padding-bottom:$mpk-padding-N;
    }
  }
}

@media screen and (max-width: $mpk-article-width){
  .mpk-article{
    > div{
      width: 100%;
    }
    .ills{
      padding-top:0;
    }
    .content{
      padding:$mpk-padding-M;
    }
  }
}