@import '../../styles/var';

.mpk-notification{
  .badge{
    display: flex;
    width: auto;
    height:24px;
    align-items: center;
    justify-content: center;
    font-size: $mpk-font-size-NS;
    border-radius: 24px;
    margin:0 16px;
    font-weight: 500;
    color:$mpk-font-color-D3;
    border:thin solid $mpk-border-color-dark;
    padding: 0 8px;
    cursor: pointer;
    &.active{
      background-color: $mpk-color-warn;
      * {
        color:white;
      }
    }
    .md-icon{
      font-size: 12px;
      margin-right: 4px;
    }
    .number{
      padding: 0 4px;
    }

    @if $mpk-appbar-theme == 'dark' {
      color:$mpk-font-color-L1;
      border-color:white;
    }
  }
  .mpk-notification-item{
    border-bottom: thin solid $mpk-border-color-dark;
    padding: $mpk-padding-N 0;
    &:last-child{
      border-bottom: none;
    }
  }
}