@import '../../styles/var';

.mpk-treeview{
  width:100%;
  ul{
    width: 100%;
    background-color: transparent !important;
    font-family: 'Roboto', serif !important;
    color:$mpk-font-color-D2 !important;
    li{
      div{
        color:$mpk-font-color-D2 !important;
        background:transparent !important;
      }
    }
  }
}