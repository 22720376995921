@import '../../styles/var';

$normal-width:400px;
$popup-width: 540px;
$popup-height: 640px;

$medium-width:640px;
$large-width:920px;

.mpk-container-form {
  .container {
    width: auto;
    margin: 0 auto;
    .cb
      >.md-paper {
        margin-left: auto;
        margin-right: auto;
        width: $normal-width;
        // padding:$mpk-padding-N;
        position:relative;
        .md-progress--linear{
          background: rgba(0,0,0,.25) !important;
          position: sticky;
          top:0;
          width:100%;
          height:2px;
          left:0;
          margin:0 !important;
        }
      }
    .header {
      text-align: center;
      font-size: $mpk-font-size-L;
      margin-bottom: $mpk-margin-N;
      p {
        font-size: $mpk-font-size-N;
        margin: 0;
        color: $mpk-font-color-D2;
      }
    }
    .footer {
      margin-top: 24px;
    }
    .action {
      > button.md-btn {
        width: 100%;
        min-width: unset;
      }
      > div:first-child {
        text-align: center;
      }
      margin-top: $mpk-margin-M;
    }
    .info {
      width: $normal-width;
      margin: 24px auto;
      z-index: 10;
      line-height: 12px;
      .pajakku{
        b { color: $mpk-primary-color;}
        .link {
          > a {
            display: inline-block;
            padding: 0 4px;
            font-size:$mpk-font-size-S;
            position: relative;
            outline:none;
            text-decoration: dotted;
            color:$mpk-font-color-D2;
            &:after{
              content:".";
              position: absolute;
              right:0;
            }
            &:first-child{
              padding-left:0;
            }
            &:last-child{
              &:after{
                display: none;
              }
            }
          }
        }
      }
    }
  }
  &.popup .cb {
    >.md-paper {
      width: $popup-width;
    }
    .info{
      width: $popup-width;
    }
    .action {
      button.md-btn {
        width: auto;
      }
    }
  }
  &.medium .cb {
    >.md-paper {
      width: $medium-width;
    }
    .info{
      width: $medium-width;
    }
    .action {
      button.md-btn {
        width: auto;
      }
    }
  }
  &.large .cb {
    >.md-paper {
      width: $large-width;
    }
    .info{
      width: $large-width;
    }
    .action {
      button.md-btn {
        width: auto;
      }
    }
  }
}

@media screen and (max-width: $mpk-media-xs) {
  .container-paper {
    .container {
      padding: 0 $mpk-padding-N;
      .md-paper {
        width: 100%;
        padding: $mpk-padding-M;
        form{
          * {
            margin-right:0;
          }
        }
      }
      .info{
        width:100%;
      }
    }
    &.flex-height {
      height: 100%;
      top: 0;
      .container {
        height: 100%;
        .brand {
          text-align: left;
          padding-top: 24px;
          margin-bottom: 24px;
          .mpk-logo {
            height: 36px;
          }
        }
        margin-top: 0;
        display: flex;
        flex-direction: column;
        .cb {
          flex: 1;
          display: flex;
          flex-direction: column;
          .header {
            flex: none;
            margin: 0;
          }
          .children {
            flex: 1;
            form {
              display: flex;
              flex-direction: column;
              width: 100%;
              height: 100%;
            }
            .action {
              margin: 0;
            }
          }
          .md-paper{
            display: flex;
            flex-direction: column;
            flex: 1;
          }
          .footer {
            flex: none;
            margin: 0;
          }
          .info {
            flex: 0;
          }
        }
      }
    }
  }
}