@import '../../styles/var';

.mpk-section-header{
  padding-top:$mpk-padding-L;
  padding-bottom: $mpk-padding-L;
  background-color: $mpk-primary-color;
  position: relative;
  .center-container{
    text-align: center;
    text-align: center;
    max-width: $mpk-media-sm;
    z-index: 2;
    > * {
      position: relative;
    }
  }
  .title{
    color:white;
    font-weight: $mpk-font-weight-M;
    position: relative;
    display: inline-block;
    padding-bottom:4px;
    font-size:$mpk-font-size-N;
    &:after{
      content:"";
      width: 100%;
      position: absolute;
      height:2px;
      left:0;
      bottom:0;
      background-color: white;
    }
  }
  .body{
    font-size: $mpk-font-size-XL;
    margin-top:$mpk-margin-N;
    font-weight: $mpk-font-weight-N;
    color:$mpk-font-color-L2;
  }
  .bg{
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    left:0;
    background-size: cover;
    z-index: 0;
  }
}