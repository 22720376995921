.mpk-current-company{
  background: transparent;
  position: relative;
  .logo{
    width: 48px;
    height: 48px;
    border-radius: 48px;
    background: #d6d0c9;
    position: relative;
    border: 2px solid white;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 48px;
      z-index: 1;
      position: relative;
    }
    .default-img{
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 0;
    }
  }
  .md-progress--linear{
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0 !important;
    padding: 0 !important;
  }
  .actions{
    .mpk-link{
      &:after{
        content:'|';
        padding: 0 4px;
      }
      &:last-child:after{
        display: none;
      }
    }
  }
}