@import '../../styles/var';

.mpk-bg-slice{
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index:  0;
  bottom: 0;
  background:  $mpk-gradient-primary-1;
  &.over-height{
    height: 140%;
  }
  .parallelogram-1{
    position: absolute;
    top: 0;
    left:  20%;
    width:  70%;
    height:  100%;
    opacity: .7;
    -webkit-transform:  skew(56deg);
    -moz-transform:  skew(56deg);
    -o-transform:  skew(56deg);
    background:  $mpk-gradient-primary-1 /* Old browsers */;
    background:  -moz-linear-gradient(top, $mpk-gradient-primary-1 56%, $mpk-gradient-primary-2 100%) /* FF3.6-15 */;
    background:  -webkit-linear-gradient(top, $mpk-gradient-primary-1 56%,$mpk-gradient-primary-2 100%) /* Chrome10-25,Safari5.1-6 */;
    background:  linear-gradient(to bottom, $mpk-gradient-primary-1 56%,$mpk-gradient-primary-2 100%) /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */;
    filter:  progid:DXImageTransform.Microsoft.gradient( startColorstr='$mpk-gradient-primary-1', endColorstr='$mpk-gradient-primary-2',GradientType=0 ) /* IE6-9 */;
  }
  .parallelogram-2 {
    position: absolute;
    top: 0;
    right: 0;
    width:  50%;
    height:  100%;
    opacity: .6;
    -webkit-transform:  skew(56deg);
    -moz-transform:  skew(56deg);
    -o-transform:  skew(56deg);
    background:  $mpk-gradient-primary-2 /* Old browsers */;
    background:  -moz-linear-gradient(top, $mpk-gradient-primary-1 0%, $mpk-gradient-primary-2 24%) /* FF3.6-15 */;
    background:  -webkit-linear-gradient(top, $mpk-gradient-primary-1 0%,$mpk-gradient-primary-2 24%) /* Chrome10-25,Safari5.1-6 */;
    background:  linear-gradient(to bottom, $mpk-gradient-primary-1 0%,$mpk-gradient-primary-2 24%) /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */;
    filter:  progid:DXImageTransform.Microsoft.gradient( startColorstr='$mpk-gradient-primary-1', endColorstr='$mpk-gradient-primary-2',GradientType=0 ) /* IE6-9 */;
  }
  .parallelogram-3 {
    position: absolute;
    top: 0;
    left: 4%;
    opacity: .5;
    width:  60%;
    height:  100%;
    -webkit-transform:  skew(-12deg);
    -moz-transform:  skew(-12deg);
    -o-transform:  skew(-12deg);
    background:  $mpk-gradient-primary-2 /* Old browsers */;
    background:  -moz-linear-gradient(top, $mpk-gradient-primary-1 0%, $mpk-gradient-primary-2 52%) /* FF3.6-15 */;
    background:  -webkit-linear-gradient(top, $mpk-gradient-primary-1 0%,$mpk-gradient-primary-2 52%) /* Chrome10-25,Safari5.1-6 */;
    background:  linear-gradient(to bottom, $mpk-gradient-primary-1 0%,$mpk-gradient-primary-2 52%) /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */;
    filter:  progid:DXImageTransform.Microsoft.gradient( startColorstr='$mpk-gradient-primary-1', endColorstr='$mpk-gradient-primary-2',GradientType=0 ) /* IE6-9 */;
  }
  .parallelogram-4 {
    position: absolute;
    top: 0;
    right: 4%;
    opacity: .4;
    width:  40%;
    height:  100%;
    -webkit-transform:  skew(-12deg);
    -moz-transform:  skew(-12deg);
    -o-transform:  skew(-12deg);
    background:  $mpk-gradient-primary-2 /* Old browsers */;
    background:  -moz-linear-gradient(top, $mpk-gradient-primary-2 0%, $mpk-gradient-primary-1 50%) /* FF3.6-15 */;
    background:  -webkit-linear-gradient(top, $mpk-gradient-primary-2 0%,$mpk-gradient-primary-1 50%) /* Chrome10-25,Safari5.1-6 */;
    background:  linear-gradient(to bottom, $mpk-gradient-primary-2 0%,$mpk-gradient-primary-1 50%) /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */;
    filter:  progid:DXImageTransform.Microsoft.gradient( startColorstr='$mpk-gradient-primary-2', endColorstr='$mpk-gradient-primary-1',GradientType=0 ) /* IE6-9 */;
  }
  .parallelogram-5 {
    position: absolute;
    top: 0;
    right: 0%;
    opacity: .3;
    width:  100%;
    height:  100%;
    -webkit-transform:  skew(-12deg);
    -moz-transform:  skew(-12deg);
    -o-transform:  skew(-12deg);
    background:  $mpk-gradient-primary-2 /* Old browsers */;
    background:  -moz-linear-gradient(top, $mpk-gradient-primary-2 0%, $mpk-gradient-primary-1 52%) /* FF3.6-15 */;
    background:  -webkit-linear-gradient(top, $mpk-gradient-primary-2 0%,$mpk-gradient-primary-1 52%) /* Chrome10-25,Safari5.1-6 */;
    background:  linear-gradient(to bottom, $mpk-gradient-primary-2 0%,$mpk-gradient-primary-1 52%) /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */;
    filter:  progid:DXImageTransform.Microsoft.gradient( startColorstr='$mpk-gradient-primary-2', endColorstr='$mpk-gradient-primary-1',GradientType=0 ) /* IE6-9 */;
  }
}