@import '../../styles/var';

.mpk-address{
  margin:0 $mpk-padding-L;
  &.light{
    color:$mpk-font-color-L2;
    .group{
      .body, .value{
        color:$mpk-font-color-L3 !important;
      }
    }
  }
  .group {
    margin-bottom:$mpk-padding-N;
    .header{
      font-weight: $mpk-font-weight-B;
      font-size: $mpk-font-size-M;
    }

    .contact {
      margin-top: $mpk-padding-S;
      .label {
        font-size: $mpk-font-size-NS;
      }
    }
  }
}

@media screen and (max-width: $mpk-media-sm){
  .mpk-address{
    width: 100%;
    margin:$mpk-padding-N 0;
    .group{
      margin-right:$mpk-padding-S;

    }
  }
}