@import '../../styles/var';

.mpk-card-article-headline{
  position: relative;
  margin-bottom: $mpk-padding-N;
  img{
    object-fit: cover;
    width: 100%;
    height:100%;
  }
  .overlay {
    position: absolute;
    bottom:0;
    padding:$mpk-padding-N;
    background-color: rgba(0,0,0,.72);
    width: 100%;
    .title {
      font-size: $mpk-font-size-XL;
      color:$mpk-font-color-L1;
    }
    .info {
      font-size: $mpk-font-size-NS;
      color: $mpk-font-color-L3;
    }
  }
}