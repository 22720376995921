@import '../../styles/var';

.mpk-step{
  overflow: hidden;
  .mpk-step-content{
    padding-top: $mpk-padding-N;
    .actions{
      margin-top: $mpk-margin-N;
    }
  }

  .mpk-step-item{
    flex: 1;
    &:last-child{
      flex: none;
      .label:after{
        display: none;
      }
    }
    &.active{
      .number{
        background: $mpk-primary-color;
        color: white;
      }
      .label{
        font-weight: 600;
      }
    }
    .number{
      width: 28px;
      height: 28px;
      font-weight: bold;
      border-radius: 50%;
      background: $mpk-grey-1;
      color: $mpk-primary-color;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      border: thin solid $mpk-primary-color;
      z-index: 10;
    }
    .label{
      display: flex;
      flex: 1;
      align-items: center;
      &:after{
        content:"";
        flex: 1;
        height: 1px;
        background: rgba(0,0,0,.25);
        margin: 0 16px;
      }
    }
  }
}

@media screen and (max-width: $mpk-media-xs){
  .mpk-step{
    .mpk-step-bar{
      align-items: flex-start;
      .mpk-step-item{
        height: 64px;
        position: relative;
        &:before{
          content:"";
          position: absolute;
          left: -2px;
          top: 50%;
          width: 1px;
          height: 100%;
          background: rgba(0,0,0,.25);
          margin: 0 16px;
        }
        &:last-child{
          &:before{
            display: none;
          }
        }
      }
    }
  }
}
