@import '../../styles/var';

.mpk-appbar{
    background:$mpk-appbar-color;
    height:64px;
    padding:0 16px;
    z-index: 20;
    position: relative;
    border-bottom:thin solid rgba(0,0,0,.04);
    box-shadow: 0px 1px 0px rgba(0,0,0,.1);
    color:$mpk-font-color-D1;
    .appbar-logo{
        cursor:pointer;
        &.light{display: none;}
    }
    .navs {
        padding:$mpk-padding-S;
        &.lt-sm{
            display: none;
            margin-left:$mpk-padding-N;
            .nav-item{
                width: 100%;
                text-align: left;
            }
        }
        .nav-item {
            font-weight: $mpk-font-weight-N;
            cursor:pointer;
            font-size:$mpk-font-size-N;
            color:$mpk-font-color-D3;
            text-transform: none;
            &.active{
                color:$mpk-primary-color !important;
                span {
                    font-weight: $mpk-font-weight-M !important;
                }
            }
        }
    }
    @if $mpk-appbar-theme == 'dark' {
        color:$mpk-font-color-L1 !important;
        .appbar-logo{
            display: none;
            &.light{display: inherit}
        }
        .appbar-button, .mpk-notification .badge > .md-icon{
            color:$mpk-font-color-L1;
        }
        .navs{
            .nav-item{
                color:$mpk-font-color-L2;
            }
        }
    }

    &.fixed{
        position: fixed;
        width: 100%;
    }
}

@media screen and (max-width: $mpk-media-sm){
    .mpk-appbar{
        .navs{
            &.gt-sm{display: none;}
            &.lt-sm{display: inline;}
        }
    }
}