@import "../../styles/var";

.mpk-sidebar-footer{
  color:$mpk-font-color-D1;
  > div{
    border-top:thin solid $mpk-border-color-dark;
    padding: 18px 0;
  }
  .link{
    display: flex;
    div{
      &:after{
        content:"|";
        padding:0 6px;
      }
      &:last-child:after{
        display: none;
      }
    }
  }
  @if $mpk-sidebar-theme == 'dark' {
    *, .md-icon {
      color:$mpk-font-color-L2;
    }
    > div {
      border-top: thin solid $mpk-border-color-white;
    }
  }
  .md-table-column{
    height: auto !important;
    padding: 0;
    &:first-child{
      padding-left: 0 !important;
    }
    button{
      height: auto;
      text-transform: none;
      padding: 0;
      &:hover{
        background: transparent;
      }
    }
    .md-icon-separator{
      .md-icon{
        color: $mpk-color-link;
      }
      .md-icon-text:last-child{
        padding-left: 4px !important;
      }
    }
  }
  .md-tile-content--left-icon{
    padding-left: 16px;
  }
}