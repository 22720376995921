@import '../../styles/var';

.mpk-status-info{
  font-weight: $mpk-font-weight-B;
  font-size: $mpk-font-size-N;
  padding:$mpk-padding-S $mpk-padding-N;
  border:2px solid $mpk-color-success;
  margin-bottom:8px;
  background:white;
  z-index: 10;
  .md-icon{
    margin-right:$mpk-margin-N;
    color:$mpk-color-success;
  }
  &.error{
    color: $mpk-color-warn;
    border-color:$mpk-color-warn;
    .md-icon{
      color:$mpk-color-warn
    }
  }
  &.success{
    color: $mpk-color-progress;
    border-color:$mpk-color-progress;
    .md-icon{
      color:$mpk-color-progress
    }
  }
}