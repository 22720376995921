@import '../../styles/var';

.mpk-sidabar-menu{
  color:$mpk-font-color-D2;
  background: inherit;
  padding: 0 8px;
  z-index: 0;
  *{
    box-sizing: border-box;
    text-align: left;
  }
  .group-divider{
    padding: 8px 16px;
    border-bottom: thin solid $mpk-border-color-dark;
    color:$mpk-font-color-D3;
    margin-top: $mpk-margin-M;
    @if $mpk-sidebar-theme == 'dark' {
      color:$mpk-font-color-L2;
    }
    &:first-child{
      margin-top: 0;
    }
  }
  .group{
    background: inherit;
    border-bottom: thin solid $mpk-border-color-dark;
    .menu-item{
      min-height: 40px;
      height: auto;
      display: flex;
      align-items: center;
      width: 100%;
      font-size: $mpk-font-size-N;
      border-radius: 0;
      color:$mpk-font-color-D2;
      text-transform: none;
      position: relative;
      &.active{
        .md-icon{
          color:$mpk-primary-color;
          position: relative;
        }
      }
      .md-icon{
        margin-right: 12px;
        font-size: 18px;
        color:rgba(0,0,0,.24);
      }
      @if $mpk-sidebar-theme == 'dark' {
        color:$mpk-font-color-L2;
        border-bottom: thin solid $mpk-border-color-white;
        .md-icon{
          color:rgba(255,255,255,.24);
        }
      }
    }
    &:last-child{
      border-bottom: none;
    }
    .subheader{
      height:48px;
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size:$mpk-font-size-N;
      color:$mpk-font-color-D2;
      background-color: inherit;
      position: -webkit-sticky;
      position: sticky;
      top:0;
      margin:0;
      z-index: 1;
      @if $mpk-sidebar-theme == 'dark' {
        color:$mpk-font-color-L2;
      }
    }
    .collapse-icon {
      display: none;
    }
    &.collapsible {
      cursor: pointer;
      .collapse-icon {
        display: block;
        position: absolute;
        right: 12px;
        color: inherit;
        font-size: 16px;
      }
    }
  }
}
