@import '../../styles/var';

.mpk-sidebar-info{
  .panel{
    background-color: #fff9f6;
    background-blend-mode: darken;
    position: relative;
    .header{
      height:40px;
      color:$mpk-primary-color;
      font-weight: 500;
      .md-icon{
        font-size: 16px;
        cursor: pointer;
        margin-left: 4px;
      }
    }
    .body{
      overflow: auto;
      max-height: 96px;
      position: relative;
    }
    &:after{
        content: '';
        width: 100%;
        height: 32px;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#fff9f6+0,fff9f6+67,fff9f6+100&0+0,1+67 */
        background: -moz-linear-gradient(top,  rgba(255,249,246,0) 0%, rgba(255,249,246,1) 67%, rgba(255,249,246,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(255,249,246,0) 0%,rgba(255,249,246,1) 67%,rgba(255,249,246,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(255,249,246,0) 0%,rgba(255,249,246,1) 67%,rgba(255,249,246,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00fff9f6', endColorstr='#fff9f6',GradientType=0 ); /* IE6-9 */
        position: absolute;
        bottom: 0;
        left: 0;
      }
    @if $mpk-sidebar-theme == 'dark' {
      background-color: rgba(255,255,255,.04);
      background-blend-mode: overlay;
      color:$mpk-font-color-L2;
      .header{
        color:$mpk-color-alert;
      }
      .md-icon{
        color:$mpk-font-color-L2;
      }
    }
  }
  .dialog {
    color:$mpk-font-color-D2;
    .title {
      color: $mpk-color-warn;
    }
  }
}