@import '../../styles/var';

.mpk-table{
  position: relative;
  th{
    .resizer{
      position: absolute;
      height: 100%;
      cursor: col-resize;
      left: 0;
      top: 0;
      .md-icon{
        font-size: 12px !important;
        padding-right: 8px;
      }
    }
  }
  p{
    margin:0px;
  }
  .mpk-link{
    p{
      color:#1e88e5;
    }
  }
  .status{
    display: inline-block;
    &.boolean{
      min-width: 48px;
      div{
        text-align: center;
      }
    }
  }

  .md-data-table--responsive {
    height: 100% !important;
  }

  .md-data-table--responsive.md-data-table--fixed{
    //height:100%;
    width: 100%;
  }

  .md-data-table__fixed-wrapper{
    width: 100%;
  }

  .md-table-column--data{
    // height: unset;
  }
  .md-table-column{
    white-space:unset;
    min-height: 120px;
  }
  .md-icon-text:last-child{
    padding-left: 4px;
  }
  .md-table-column--header .md-icon{
    font-size: 12px !important;
  }

  #mpk-inner-tbl{
    overflow-x:auto;
    overflow-y:hidden ;
  }

  .query-info{
    position: absolute;
    width: 100%;
    height:100%;
    /*.not-found {
      height:100%;
      img {
        height: 50%;
        margin-right: 24px;
      }
      .info{
        max-width: 25%;
        div:first-child{
          color:$mpk-color-warn;
        }
        .todo{
          margin-top:4px;
        }
      }
    }*/
  }

  .header-loader{
    height:56px;
    display: flex;
    position:absolute;
    top:0;
    left:0;
    flex-direction: column;
    justify-content: center;
    padding:0 $mpk-padding-N;
  }

  .md-tile-addon{
    line-height: 1 !important;
  }
  .md-tile-content--left-icon{
    padding-left:16px !important;
  }
  .md-icon{
    &.mdi{
      font-size: 20px;
    }
  }

  .md-table-column__fixed.md-table-column__fixed--header.sortable{
    cursor: pointer;
  }

  .md-data-table--responsive.md-data-table--fixed{
    display:grid;
  }

  .md-data-table__fixed-wrapper{
    min-width: unset;
  }

  .md-progress--linear{
    margin:0;
    position: absolute;
    top:56px;
    height:2px;
    background:#f2f2f2;
    &.show{
      opacity: 1;
      animation-name: fade-in;
      animation-duration: 1s;
    }
    &.hide{
      opacity: 0;
      animation-name: fade-out;
      animation-duration: 1s;
    }
  }
}

.mpk-table-setting{
  width:240px;
  .md-tab{
    min-width: 50%;
  }
  .md-tab-indicator{
    width:50% !important;
  }
  .md-tab--inactive{
    color:$mpk-font-color-D2 !important;
  }
}

/*.md-data-table__scroll-wrapper{
  height:auto !important;
}*/

@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fade-out {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

@media screen and (max-width:$mpk-media-sm){
  .mpk-table{
    .query-info{
      .not-found{
        img{
          height:auto;
          width:50%;
          margin-right: 0;
          margin-bottom: 24px;
        }
        .info{
          max-width: 80%;
          text-align: center;
        }
      }
    }
  }
}

@media screen and (max-width:$mpk-media-xs){
  .mpk-table-setting{
    width:100%;
  }
}