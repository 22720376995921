.support-mpk{
  position: absolute;
  z-index: 1000;
  bottom: 24px;
  right: 24px;
  .md-tile-content--left-icon{
    padding-left: 16px !important;
  }
  .menu-items{
    min-width: 240px;
    position: absolute;
    right: 56px;
    top: -120px;
    padding: 8px;
    .md-tile-addon--icon{
      height: unset;
    }
    .info{
      font-size: 12px;
      color: #aaa;
      padding: 8px 16px;
    }
    // transition: top .4s ease-out;
    .header{
      color: #2883e5;
      font-weight: bold;
      padding: 8px 16px 0 16px;
      display: flex;
      align-items: center;
      > .md-icon{
        color: inherit;
        margin-right: 10px;
      }
    }
  }
}